.popover-content {
    background-color: #4CAF50;
    border: 1px solid black;
    padding: 10px;
    z-index: 1000;
  }

  .close-button {
    background-color: #116914;
    color: white;
    padding: 5px;
    border: 1px solid black;
    border-radius: 5px;
  }