.calendar-container {
    width: 100%;
    font-family: 'Arial', sans-serif;
  }
  
  .fc .fc-daygrid-event {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .fc .conflict {
    background-color: #FF6969 !important;
    border-color: #FF6969 !important;
    color: white !important;
  }
  
  .fc .no-conflict {
    background-color: #90D26D !important;
    border-color: #90D26D !important;
    color: white !important;
  }
  
  .fc-event-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .fc-event-custom .fc-event-title {
    font-weight: bold;
  }
  