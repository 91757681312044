.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
}

.main-content {
  display: grid;
  grid-template-columns: 2fr 1fr; /* 8 parts for the scheduler and 4 parts for the form */
  gap: 20px;
  padding: 20px;
  flex: 1;
}

header, footer {
  flex-shrink: 0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  z-index: 10;
}

.success-notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 1em;
  background-color: #dff0d8;
  color: #3c763d;
  border: 1px solid #d6e9c6;
  border-radius: 4px;
  text-align: center;
  z-index: 11;
}
