.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  height: 100vh;
  margin-top: -100px;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

.shift-form {
  display: flex;
  flex-direction: column;
  width: 400px; /* Set a fixed width for the form */
}

.shift-form div {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shift-form label {
  margin-right: 10px;
  width: 100px; /* Set a fixed width for labels */
}

.shift-form input, .shift-form select {
  padding: 5px;
  width: 100%; /* Ensure input fields and dropdowns take the full width */
  box-sizing: border-box;
}

.shift-form button {
  padding: 10px;
  background-color: #6295A2;
  color: white;
  border: none;
  cursor: pointer;
  width: 100%; /* Ensure the button takes the full width */
  align-self: center; /* Center the button */
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  z-index: 10;
}

.success-notification {
  margin-top: 1em;
  padding: 1em;
  background-color: #dff0d8;
  color: #3c763d;
  border: 1px solid #d6e9c6;
  border-radius: 4px;
  text-align: center;
}

.error-notification {
  margin-top: 1em;
  padding: 1em;
  background-color: #f2dede;
  color: #a94442;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  text-align: center;
}
